





































































// Core
import {Component, Vue} from 'vue-property-decorator'
import {namespace} from 'vuex-class'
import Debounce from '@/decorators/Debounce'

// Interfaces
import {ISelect} from '@/interfaces/Interface'
import {Project, ProjectsState} from '@store/projects/interfaces'
import {ProjectsActions} from '@/store/projects/types'
import {Meta, Paginate, RequestParams} from '@/store/interfaces'

const NSProjects = namespace('projectsModule')

@Component({
  name: 'Projects',

  components: {
    'v-project-card': () => import('./components/ProjectCard.vue'),
    'v-status-dialog': () => import('./components/ProjectEditStatusesForm.vue'),
    'v-no-content': () => import('@/components/NoContent.vue'),
  },
})
export default class ProjectsComponent extends Vue {
  @NSProjects.State((state: ProjectsState) => state.projects.data) private projects!: Project[]

  @NSProjects.State((state: ProjectsState) => state.projects.meta) private meta!: Meta

  @NSProjects.Action(ProjectsActions.A_GET_PROJECTS)
  private fetchProjects!: (params?: RequestParams) => Promise<Paginate<Project>>

  private statusOptions: ISelect[] = [
    {
      label: 'Активен',
      value: '1',
    },
    {
      label: 'Не активен',
      value: '0',
    },
  ]

  private params: RequestParams = {
    isActive: null,
    search: '',
  }

  private loading:boolean = true

  private addStatusRulesDialog: boolean = false
  private addStatusRulesProjectId: number = 0

  private addStatusRules(project_id: number) {
    this.addStatusRulesProjectId = project_id
    this.addStatusRulesDialog = true
  }

  protected created() {
    this.changePage()
  }

  @Debounce(1000)
  private handleChangeSearch() {
    const search = this.params.search as string

    if (search.length >= 3 || search.length === 0) {
      this.changePage()
    }
  }

  private changeStatus() {
    this.changePage()
  }

  private async changePage(page = 1) {
    this.loading = true
    await this.fetchProjects({page, ...this.params})
    this.loading = false
    this.$scrollToTop()
  }
}
